@import "assets/css/theme.scss";
$green: 008000;
$white: #fff;

.toggle {
    margin-top: 8px;
    margin-bottom: 8px;
}

.mt-20 {
    margin-top:20px;
}
.mt-30 {
    margin-top:30px;
}
fieldset {
    background: #f6f7f9;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 20px 8px 12px 8px;
    position: relative;
    legend {
        font-size: 1em;
        font-weight: normal;
        position: absolute;
        top: 0;
    }
    .toggle {
        margin-top: 30px;
    }
}
.contract {
    font-family: "Courier New", serif, monospace;
    max-height: 50vh;
    overflow-y: scroll;
    ol.roman {
        list-style-type: upper-roman;
    }

    ol.alpha {
        list-style-type: lower-alpha;
    }

    ol.decimal {
        list-style-type: decimal;
    }

    ol.dash {
        li {
            list-style-type: none;
        }
        li:before {
            content: "- " !important;
        }
    }
}
.sidebar-section {
    h6.list-group-item-title {
        font-size: .85rem;
    }
    .bg-excel{
        background-color: #008000!important;
        color: $white;
        &:hover {
            opacity: .5;
            color: $white;
        }
    }
    .bg-pdf{
        background-color: #e63a2b!important;
        color: $white;
        &:hover {
            opacity: .5;
            color: $white;
        }
    }
    .bg-ppt{
        background-color: #cc4124!important;
        color: $white;
        &:hover {
            opacity: .5;
            color: $white;
        }
    }
    .bg-word{
        background-color: #014e91!important;
        color: $white;
        &:hover {
            opacity: .5;
            color: $white;
        }
    }
}

.link01, .link02, .link03, .link04{
    font-size: 1rem;
    font-weight: bold;
}

.link01{
    color: #9eb749;
    background-color: #eeffba;
}

.link02{
    color: darkblue;
    background-color: #dedad4;
}
.link03{
    color: #ff8100;
    background-color: #ffe9b7;
}

.link04{
    color: #2d2d2d;
    background-color: #dedede;
}

ul.listStyleUl, ul.listStyleUl02{
    list-style: none;
    width:100%;
}

ul.listStyleUl{
    padding: 0px 0px 0px 20px;

}

ul.listStyleUl02{

}

.p5-10{
    padding: 5px 10px;
    word-break: break-word;
}

.textWhite{
    color: #ffffff;
}

.textGreen{
    color: #b2ce4e
}

.textBlue{
    color: #00506f;
}
.textOrange{
    color: #ff8100;
}

.textGrey{
    color: #2d2d2d;
}

.textBold{
    font-weight: bold;
}

.bgGreen{
    color: #ffffff;
    list-style: none;
    background-color: #9eb749;
    padding: 5px;
}

.bbGreen, .bbBlue, .bbOrange, .bbGrey{
    padding: 10px;
}

.bbGreen{
    border-bottom: 1px solid #b2ce4e;
}

.bbBlue{
    border-bottom: 1px solid #00506f;
}

.bbOrange{
    border-bottom: 1px solid #ff8100;
}

.bbGrey{
    border-bottom: 1px solid #2d2d2d;
}
.bbGreen:last-child, .bbBlue:last-child, .bbOrange:last-child, .bbGrey:last-child{
    border-bottom: 0px;
}
.nav-tabs a.nav-link.active {
    border-color: #9EB749;
}
.feedback {
    display: inline-block;
    label {
        font-size: 0 !important;
    }
    [type=radio] {
        -webkit-appearance: none;
        appearance: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 75px;
        cursor: pointer;
        height: 75px;
        line-height: 75px;
        margin: auto;
        position: absolute;
        width: 75px;
    }
    div.form-check {
        float: left;
        margin-right: 50px;
        text-align: center;
        width: 80px;
        [type=radio] {
            background: url("../images/sprite.png") no-repeat;
        }
    }
    div.form-check:nth-child(1){
        [type=radio] {
            background-position: -225px -75px;
            &:checked {
                background-position: -225px 0;
            }
        }
    }
    div.form-check:nth-child(2){
        [type=radio] {
            background-position: -150px -75px;
            &:checked {
                background-position: -150px 0;
            }
        }
    }
    div.form-check:nth-child(3){
        [type=radio] {
            background-position: -75px -75px;
            &:checked {
                background-position: -75px 0;
            }
        }
    }
    div.form-check:nth-child(4){
        [type=radio] {
            background-position: 0 -75px;
            &:checked {
                background-position: 0 0;
            }
        }
    }

    /* CHECKED STYLES */

    [type=radio]:focus {
        outline: none !important;
    }
}
.feedback {
    line-height: 75px;
}
.input-group select {
    border-left: 1px solid #c6c9d5 !important;
    border-top: 1px solid #c6c9d5 !important;
    border-bottom: 1px solid #c6c9d5 !important;
    padding-bottom: 3px !important;
    padding-top: 3px !important;
}
